<template>
  <section class="baseBanner">
    <slot></slot>
    <!-- <img
      src="@/assets/image/Video/Light.png"
      alt="燈籠爲背景裝飾圖"
      class="theCameraBanner__lightImage"
    />
    <img
      src="@/assets/image/Video/Camera.png"
      alt="相機爲背景裝飾圖"
      class="theCameraBanner__cameraImage"
    />

    <img
      src="@/assets/image/Video/Mountain.png"
      alt="山爲背景裝飾圖"
      class="theCameraBanner__mountainImage"
    />
    <slot></slot> -->
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss">
.baseBanner {
  background-image: url('~@/assets/image/common/GrayBanner.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 7.6rem 0;
  position: relative;
}

@media (max-width: 768px) {
  .baseBanner {
    background-image: url('~@/assets/image/common/GrayBannerMobile.png');
    padding: 3.2rem 2rem 13.4rem 2rem;
    position: relative;
  }
}
</style>
