<template>
  <BaseBanner>
    <div class="container cultureBanner">
      <img
        class="cultureBanner__img cultureBanner__sunImg"
        src="@/assets/image/Culture/Sun.png"
        alt=""
      />

      <img
        class="cultureBanner__img cultureBanner__oneMountainImg"
        src="@/assets/image/Culture/OneMountain.png"
        alt=""
      />

      <div class="cultureBanner__textContainer">
        <h2 class="cultureBanner__title" :style="titleImageStyle">
          {{ pageInfo.title }}
        </h2>

        <div class="cultureBanner__rightButtonBar">
          <img
            class="cultureBanner__peopleImg"
            src="@/assets/image/Culture/People.png"
            alt=""
          />
          <div class="cultureBanner__buttonContainer">
            <router-link
              v-for="(button, index) in pageInfo.buttonLists"
              :key="index"
              class="cultureBanner__button clickable"
              :style="`background-image: url(${button.image});`"
              :alt="`點擊前往數位學習-${button.text}`"
              :to="{ name: button.routeName }"
            >
              {{ button.text }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </BaseBanner>
</template>

<script>
import BaseBanner from '@/components/BaseBanner.vue'
const waterGoldButton = {
  text: '水金九景點',
  image: require('@/assets/image/Culture/WaterGoldButton.png'),
  routeName: 'CultureWaterGold'
}
const mineStoryButton = {
  text: '礦山故事',
  image: require('@/assets/image/Culture/MineStoryButton.png'),
  routeName: 'CultureStory'
}

const mapButton = {
  text: '散步地圖',
  image: require('@/assets/image/Culture/MapButton.png'),
  routeName: 'CultureMap'
}

export default {
  components: {
    BaseBanner
  },
  data() {
    return {
      pageTable: {
        CultureMap: {
          title: '散步地圖',
          titleImage: require('@/assets/image/Culture/MapTitle.png'),
          buttonLists: [waterGoldButton, mineStoryButton]
        },
        CultureStory: {
          title: '礦山故事',
          titleImage: require('@/assets/image/Culture/StoryTitle.png'),
          buttonLists: [mapButton, waterGoldButton]
        },
        CultureWaterGold: {
          title: '水金九景點',
          titleImage: require('@/assets/image/Culture/WaterGoldTitle.png'),
          buttonLists: [mapButton, mineStoryButton]
        }
      }
    }
  },
  computed: {
    pageInfo() {
      const routeName = this.$route.name
      return this.pageTable[routeName]
    },
    titleImageStyle() {
      const routeName = this.$route.name
      const titleImage = this.pageInfo.titleImage
      if (routeName === 'CultureWaterGold') {
        return `background-image: url(${titleImage}); width: 18.3rem;`
      }
      return `background-image: url(${titleImage});`
    }
  }
}
</script>

<style lang="scss">
.cultureBanner {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);

  &__img {
    object-fit: contain;
  }

  &__peopleImg {
    transform: translateY(12px);
  }

  &__sunImg {
    position: absolute;
    width: 4.7rem;
    height: 4.7rem;
    top: 0;
    left: 0;
  }

  &__oneMountainImg {
    position: absolute;
    width: 16.3rem;
    height: 9.25rem;
    bottom: 0;
    left: 1rem;
  }

  &__textContainer {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 16rem;
  }

  &__title {
    flex-shrink: 0;
    width: 14.55rem;
    height: 4.55rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__button {
    width: 15.5rem;
    height: 5.5rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    margin: 0.5rem 1rem;
    flex-shrink: 0;
  }

  &__buttonContainer {
    display: flex;
    min-width: 15.5rem;
    max-width: 34rem;
    align-items: center;
  }

  &__rightButtonBar {
    margin-left: auto;
    display: flex;
  }
}

@media (max-width: 875px) {
  .cultureBanner {
    &__buttonContainer {
      flex-wrap: wrap;
      width: 15.5rem;
    }
  }
}

@media (max-width: 768px) {
  .cultureBanner {
    position: static;
    top: 0;
    left: 0;
    right: 0;
    transform: none;

    &__title {
      width: 100%;
      margin-bottom: 3.25rem;
    }

    &__buttonContainer {
      width: 100%;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    &__textContainer {
      padding: 3.1rem 0 0 0;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__peopleImg {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__rightButtonBar {
      margin: 0;
      width: 100%;
      justify-content: center;
    }

    &__sunImg {
      top: unset;
      bottom: 8.3rem;
    }

    &__button {
      flex: 1;
    }
  }
}

@media (max-width: 340px) {
  .cultureBanner {
    &__buttonContainer {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
</style>
