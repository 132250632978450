<template>
  <main class="culture">
    <CultureBanner />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </main>
</template>

<script>
import CultureBanner from '@/components/Culture/CultureBanner.vue'
export default {
  components: { CultureBanner }
}
</script>

<style lang="scss"></style>
